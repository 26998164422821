export default {
  BOOKING_LIST: "Liste des réservations",
  ADD_BOOKING: "Ajouter réservation",
  BOOKING_ADDED: "Réservation confirmée",
  EDIT_BOOKING: "Modifier la réservation",
  BOOKING_UPDATED: "Réservation mis à jour",
  DELETE_THIS_BOOKING: "Supprimer cette réservation ?",
  BOOKING_DELETED: "Réservation supprimée",
  CAPACITY: "Nombre de personnes",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Animaux",
  CHILDREN_CAPACITY: "Enfants",
  BOOK_SPOT: "Réserver place",
  STARTING_FROM: "À partir de",
  PRICE_PER_NIGHT: "par nuit",
  AVAILABILITY: "Disponibilité",
  AVAILABLE: "Disponible",
  NOT_AVAILABLE: "Non-disponible",
  PARTIALLY_AVAILABLE: "Partiellement disponible",
  FILTER_BY_SPOT_CATEGORY: "Terrain ou emplacement",
  FILTER_BY_SPOT_TYPE: "Service",
  FILTER_BY_SPOT_LENGTH: "Dimensions de l'équipement",
  FILTER_BY_EQUIPMENT: "Équipement",
  UNIT_FEET: "pieds",
  SPOTS_LIST_FOUND: "{total} emplacements trouvés",
  NEW_BOOKING: "Nouvelle réservation",
};
