<template>
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.NEW_BOOKING") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_BOOKINGS)">
      <router-link
        :to="{ name: 'List Bookings' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.BOOKING_LIST") }}
          </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
