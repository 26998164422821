export default {
  PERM_VIEW_APP_DEFAULT: "view app default",
  PERM_VIEW_APP_CRM: "view app crm",
  PERM_VIEW_APP_SALES: "view app sales",
  PERM_VIEW_APP_PURCHASES: "view app purchases",
  PERM_VIEW_APP_INVENTORY: "view app inventory",
  PERM_VIEW_APP_FILE_EXPLORER: "view app file explorer",
  PERM_VIEW_APP_RESELLER: "view app reseller",
  PERM_VIEW_APP_ADMIN: "view app admin",
  PERM_VIEW_APP_AUCTION: "view app auction",
  PERM_VIEW_APP_SPOT: "view app spot",
  PERM_VIEW_APP_CONFIGURATION: "view app configuration",
  PERM_VIEW_APP_BOOKING: "view app reservation",

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "view module subscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "view any subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "view subscriptions",
  PERM_CREATE_SUBSCRIPTIONS: "create subscriptions",
  PERM_EDIT_SUBSCRIPTIONS: "edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "delete subscriptions",

  PERM_VIEW_MODULE_PACKAGES: "view module packages",
  PERM_VIEW_ANY_PACKAGES: "view any packages",
  PERM_VIEW_PACKAGES: "view packages",
  PERM_CREATE_PACKAGES: "create packages",
  PERM_EDIT_PACKAGES: "edit packages",
  PERM_DELETE_PACKAGES: "delete packages",

  PERM_VIEW_MODULE_CUSTOMERS: "view module customers",
  PERM_VIEW_ANY_CUSTOMERS: "view any customers",
  PERM_VIEW_CUSTOMERS: "view customer",
  PERM_CREATE_CUSTOMERS: "create customer",
  PERM_EDIT_CUSTOMERS: "edit customer",
  PERM_DELETE_CUSTOMERS: "delete customer",

  PERM_VIEW_MODULE_ORGANIZATIONS: "view module organizations",
  PERM_VIEW_ANY_ORGANIZATIONS: "view any organizations",
  PERM_VIEW_ORGANIZATIONS: "view organization",
  PERM_CREATE_ORGANIZATIONS: "create organization",
  PERM_EDIT_ORGANIZATIONS: "edit organization",
  PERM_EDIT_OWN_ORGANIZATIONS: "edit own organization",
  PERM_VIEW_OWN_ORGANIZATIONS: "view own organization",
  PERM_DELETE_ORGANIZATIONS: "delete organization",

  PERM_VIEW_MODULE_RESELLERS: "view module resellers",
  PERM_VIEW_ANY_RESELLERS: "view any resellers",
  PERM_VIEW_RESELLERS: "view resellers",
  PERM_CREATE_RESELLERS: "create resellers",
  PERM_EDIT_RESELLERS: "edit resellers",
  PERM_EDIT_OWN_RESELLERS: "edit own resellers",
  PERM_DELETE_RESELLERS: "delete resellers",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "view module reseller products",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "view any reseller products",
  PERM_VIEW_RESELLER_PRODUCTS: "view reseller products",
  PERM_CREATE_RESELLER_PRODUCTS: "create reseller products",
  PERM_EDIT_RESELLER_PRODUCTS: "edit reseller products",
  PERM_DELETE_RESELLER_PRODUCTS: "delete reseller products",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "view module reseller services",
  PERM_VIEW_ANY_RESELLER_SERVICES: "view any reseller services",
  PERM_VIEW_RESELLER_SERVICES: "view reseller services",
  PERM_CREATE_RESELLER_SERVICES: "create reseller services",
  PERM_EDIT_RESELLER_SERVICES: "edit reseller services",
  PERM_DELETE_RESELLER_SERVICES: "delete reseller services",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "view module reseller invoices",
  PERM_VIEW_ANY_RESELLER_INVOICES: "view any reseller invoices",
  PERM_VIEW_RESELLER_INVOICES: "view reseller invoice",
  PERM_CREATE_RESELLER_INVOICES: "create reseller invoice",
  PERM_EDIT_RESELLER_INVOICES: "edit reseller invoice",
  PERM_DELETE_RESELLER_INVOICES: "delete reseller invoice",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "view module reseller payments",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "view any reseller payments",
  PERM_VIEW_RESELLER_PAYMENTS: "view reseller payments",
  PERM_CREATE_RESELLER_PAYMENTS: "create reseller payments",

  PERM_VIEW_MODULE_USERS: "view module users",
  PERM_VIEW_ANY_USERS: "view any users",
  PERM_VIEW_USERS: "view users",
  PERM_CREATE_USERS: "create users",
  PERM_EDIT_USERS: "edit users",
  PERM_DELETE_USERS: "delete users",

  PERM_VIEW_MODULE_PRODUCTS: "view module products",
  PERM_VIEW_ANY_PRODUCTS: "view any products",
  PERM_VIEW_PRODUCTS: "view products",
  PERM_CREATE_PRODUCTS: "create products",
  PERM_EDIT_PRODUCTS: "edit products",
  PERM_DELETE_PRODUCTS: "delete products",

  PERM_VIEW_MODULE_ROLES: "view module roles",
  PERM_VIEW_ANY_ROLES: "view any roles",
  PERM_VIEW_ROLES: "view roles",
  PERM_CREATE_ROLES: "create roles",
  PERM_EDIT_ROLES: "edit roles",
  PERM_DELETE_ROLES: "delete roles",

  PERM_VIEW_ANY_PERMISSIONS: "view any permissions",
  PERM_VIEW_PERMISSIONS: "view permissions",

  PERM_VIEW_ANY_LOGS: "view any logs",
  PERM_VIEW_LOGS: "view logs",

  PERM_VIEW_MODULE_CONTACTS: "view module contacts",
  PERM_VIEW_ANY_CONTACTS: "view any contacts",
  PERM_VIEW_CONTACTS: "view contacts",
  PERM_CREATE_CONTACTS: "create contacts",
  PERM_EDIT_CONTACTS: "edit contacts",
  PERM_DELETE_CONTACTS: "delete contacts",

  PERM_VIEW_MODULE_SUPPLIERS: "view module suppliers",
  PERM_VIEW_ANY_SUPPLIERS: "view any suppliers",
  PERM_VIEW_SUPPLIERS: "view suppliers",
  PERM_CREATE_SUPPLIERS: "create suppliers",
  PERM_EDIT_SUPPLIERS: "edit suppliers",
  PERM_DELETE_SUPPLIERS: "delete suppliers",

  PERM_VIEW_MODULE_LOCATIONS: "view module locations",
  PERM_VIEW_ANY_LOCATIONS: "view any locations",
  PERM_VIEW_LOCATIONS: "view locations",
  PERM_CREATE_LOCATIONS: "create locations",
  PERM_EDIT_LOCATIONS: "edit locations",
  PERM_EDIT_HIS_LOCATIONS: "edit his locations",
  PERM_DELETE_LOCATIONS: "delete locations",

  PERM_VIEW_MODULE_FILES: "view module files",
  PERM_VIEW_ANY_FILES: "view any files",
  PERM_VIEW_FILES: "view files",
  PERM_CREATE_FILES: "create files",
  PERM_EDIT_FILES: "edit files",
  PERM_DELETE_FILES: "delete files",
  PERM_MANAGE_ACCESS_FILES: "manage access files",

  PERM_VIEW_MODULE_FOLDERS: "view module folders",
  PERM_VIEW_ANY_FOLDERS: "view any folders",
  PERM_VIEW_FOLDERS: "view folders",
  PERM_CREATE_FOLDERS: "create folders",
  PERM_EDIT_FOLDERS: "edit folders",
  PERM_DELETE_FOLDERS: "delete folders",
  PERM_MANAGE_ACCESS_FOLDERS: "manage access folders",
  PERM_MANAGE_LOCKED_FOLDERS: "manage locked folders",

  PERM_VIEW_MODULE_WAREHOUSES: "view module warehouses",
  PERM_VIEW_ANY_WAREHOUSES: "view any warehouses",
  PERM_VIEW_WAREHOUSES: "view warehouses",
  PERM_CREATE_WAREHOUSES: "create warehouses",
  PERM_EDIT_WAREHOUSES: "edit warehouses",
  PERM_DELETE_WAREHOUSES: "delete warehouses",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "view module purchases orders",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "view any purchases orders",
  PERM_VIEW_PURCHASES_ORDERS: "view purchases orders",
  PERM_CREATE_PURCHASES_ORDERS: "create purchases orders",
  PERM_EDIT_PURCHASES_ORDERS: "edit purchases orders",
  PERM_DELETE_PURCHASES_ORDERS: "delete purchases orders",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "view module purchases invoices",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "view any purchases invoices",
  PERM_VIEW_PURCHASES_INVOICES: "view purchases invoices",
  PERM_CREATE_PURCHASES_INVOICES: "create purchases invoices",
  PERM_EDIT_PURCHASES_INVOICES: "edit purchases invoices",
  PERM_DELETE_PURCHASES_INVOICES: "delete purchases invoices",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES: "view module purchases deliveries",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "view any purchases deliveries",
  PERM_VIEW_PURCHASES_DELIVERIES: "view purchases deliveries",
  PERM_CREATE_PURCHASES_DELIVERIES: "create purchases deliveries",
  PERM_EDIT_PURCHASES_DELIVERIES: "edit purchases deliveries",
  PERM_DELETE_PURCHASES_DELIVERIES: "delete purchases deliveries",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "view module purchases payments",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "view any purchases payments",
  PERM_VIEW_PURCHASES_PAYMENTS: "view purchases payments",
  PERM_CREATE_PURCHASES_PAYMENTS: "create purchases payments",
  PERM_EDIT_PURCHASES_PAYMENTS: "edit purchases payments",
  PERM_DELETE_PURCHASES_PAYMENTS: "delete purchases payments",

  PERM_VIEW_MODULE_SALES_ORDERS: "view module sales orders",
  PERM_VIEW_ANY_SALES_ORDERS: "view any sales orders",
  PERM_VIEW_SALES_ORDERS: "view sales orders",
  PERM_CREATE_SALES_ORDERS: "create sales orders",
  PERM_EDIT_SALES_ORDERS: "edit sales orders",
  PERM_DELETE_SALES_ORDERS: "delete sales orders",

  PERM_VIEW_MODULE_SALES_INVOICES: "view module sales invoices",
  PERM_VIEW_ANY_SALES_INVOICES: "view any sales invoices",
  PERM_VIEW_SALES_INVOICES: "view sales invoices",
  PERM_CREATE_SALES_INVOICES: "create sales invoices",
  PERM_EDIT_SALES_INVOICES: "edit sales invoices",
  PERM_DELETE_SALES_INVOICES: "delete sales invoices",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "view module sales deliveries",
  PERM_VIEW_ANY_SALES_DELIVERIES: "view any sales deliveries",
  PERM_VIEW_SALES_DELIVERIES: "view sales deliveries",
  PERM_CREATE_SALES_DELIVERIES: "create sales deliveries",
  PERM_EDIT_SALES_DELIVERIES: "edit sales deliveries",
  PERM_DELETE_SALES_DELIVERIES: "delete sales deliveries",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "view module sales payments",
  PERM_VIEW_ANY_SALES_PAYMENTS: "view any sales payments",
  PERM_VIEW_SALES_PAYMENTS: "view sales payments",
  PERM_CREATE_SALES_PAYMENTS: "create sales payments",
  PERM_EDIT_SALES_PAYMENTS: "edit sales payments",
  PERM_DELETE_SALES_PAYMENTS: "delete sales payments",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "view module stock movements",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "view any stock movements",
  PERM_VIEW_STOCK_MOVEMENTS: "view stock movements",
  PERM_CREATE_STOCK_MOVEMENTS: "create stock movements",
  PERM_EDIT_STOCK_MOVEMENTS: "edit stock movements",
  PERM_DELETE_STOCK_MOVEMENTS: "delete stock movements",

  PERM_VIEW_MODULE_IMPORTS: "view module imports",
  PERM_VIEW_ANY_IMPORTS: "view any imports",
  PERM_VIEW_IMPORTS: "view imports",
  PERM_CREATE_IMPORTS: "create imports",
  PERM_EDIT_IMPORTS: "edit imports",
  PERM_DELETE_IMPORTS: "delete imports",

  PERM_VIEW_MODULE_PROPERTIES: "view module properties",
  PERM_VIEW_ANY_PROPERTIES: "view any properties",
  PERM_VIEW_PROPERTIES: "view properties",
  PERM_CREATE_PROPERTIES: "create properties",
  PERM_EDIT_PROPERTIES: "edit properties",
  PERM_DELETE_PROPERTIES: "delete properties",
  PERM_TOGGLE_ACTIVATION_PROPERTIES: "toggle activation properties",
  PERM_ACCESS_ALL_FIELDS_PROPERTIES: "access all fields properties",
  PERM_EXPORTS_PROPERTIES: "exports properties",
  PERM_EDIT_PROPERTY_NUMBER_PROPERTIES: "edit property number properties",
  PERM_CHANGE_APPROVED_STATUS_PROPERTIES: "change approved status properties",

  PERM_VIEW_MODULE_AUCTIONS: "view module auctions",
  PERM_VIEW_ANY_AUCTIONS: "view any auctions",
  PERM_VIEW_AUCTIONS: "view auctions",
  PERM_CREATE_AUCTIONS: "create auctions",
  PERM_EDIT_AUCTIONS: "edit auctions",
  PERM_DELETE_AUCTIONS: "delete auctions",

  PERM_VIEW_MODULE_PROPERTY_TRANSACTIONS: "view module property transactions",
  PERM_VIEW_ANY_PROPERTY_TRANSACTIONS: "view any property transactions",
  PERM_VIEW_PROPERTY_TRANSACTIONS: "view property transactions",
  PERM_CREATE_PROPERTY_TRANSACTIONS: "create property transactions",
  PERM_EDIT_PROPERTY_TRANSACTIONS: "edit property transactions",
  PERM_DELETE_PROPERTY_TRANSACTIONS: "delete property transactions",
  PERM_VIEW_ANY_TRANSACTIONS_PROPERTIES: "view any transactions properties",
  PERM_CREATE_TRANSACTIONS_PROPERTIES: "create transactions properties",
  PERM_DELETE_TRANSACTIONS_PROPERTIES: "delete transactions properties",

  PERM_VIEW_MODULE_SPOTS: "view module spots",
  PERM_VIEW_ANY_SPOTS: "view any spots",
  PERM_VIEW_SPOTS: "view spots",
  PERM_CREATE_SPOTS: "create spots",
  PERM_EDIT_SPOTS: "edit spots",
  PERM_DELETE_SPOTS: "delete spots",

  PERM_VIEW_MODULE_SPOT_CATEGORIES: "view module spot categories",
  PERM_VIEW_ANY_SPOT_CATEGORIES: "view any spot categories",
  PERM_VIEW_SPOT_CATEGORIES: "view spot categories",
  PERM_CREATE_SPOT_CATEGORIES: "create spot categories",
  PERM_EDIT_SPOT_CATEGORIES: "edit spot categories",
  PERM_DELETE_SPOT_CATEGORIES: "delete spot categories",

  PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES: "view module equipment categories",
  PERM_VIEW_ANY_EQUIPMENT_CATEGORIES: "view any equipment categories",
  PERM_VIEW_EQUIPMENT_CATEGORIES: "view equipment categories",
  PERM_CREATE_EQUIPMENT_CATEGORIES: "create equipment categories",
  PERM_EDIT_EQUIPMENT_CATEGORIES: "edit equipment categories",
  PERM_DELETE_EQUIPMENT_CATEGORIES: "delete equipment categories",

  PERM_VIEW_MODULE_EQUIPMENTS: "view module equipments",
  PERM_VIEW_ANY_EQUIPMENTS: "view any equipments",
  PERM_VIEW_EQUIPMENTS: "view equipments",
  PERM_CREATE_EQUIPMENTS: "create equipments",
  PERM_EDIT_EQUIPMENTS: "edit equipments",
  PERM_DELETE_EQUIPMENTS: "delete equipments",

  PERM_VIEW_MODULE_SPOT_TYPES: "view module spot types",
  PERM_VIEW_ANY_SPOT_TYPES: "view any spot types",
  PERM_VIEW_SPOT_TYPES: "view spot types",
  PERM_CREATE_SPOT_TYPES: "create spot types",
  PERM_EDIT_SPOT_TYPES: "edit spot types",
  PERM_DELETE_SPOT_TYPES: "delete spot types",

  PERM_VIEW_MODULE_BOOKINGS: "view module bookings",
  PERM_VIEW_ANY_BOOKINGS: "view any bookings",
  PERM_VIEW_BOOKINGS: "view bookings",
  PERM_CREATE_BOOKINGS: "create bookings",
  PERM_EDIT_BOOKINGS: "edit bookings",
  PERM_DELETE_BOOKINGS: "delete bookings",

  PERM_VIEW_MODULE_OPTIONS: "view module options",
  PERM_VIEW_ANY_OPTIONS: "view any options",
  PERM_EDIT_OPTIONS: "edit options",
};
