<template>
  <Header class="header">
    <div class="header-inner">
      <div class="lang">
        <base-dropdown
          title-classes="btn btn-sm btn-neutral"
          menu-on-right
          id="lang-switcher"
          :has-toggle="false"
          menuClasses="lang-switcher"
        >
          <template slot="title">
            <span class="text">{{ $i18n.locale }}</span>
            <span class="icon">
              <i class="fal fa-chevron-down"></i>
            </span>
          </template>
          <li v-for="lang in langs" :key="lang.id">
            <a
              class="dropdown-item"
              v-bind:class="{ active: lang.slug === $i18n.locale }"
              @click="selectLang(lang.slug)"
            >
              <img src="../../../../../public/img/canada-flag.svg" alt="flag" />
              <span>{{ lang.text }}</span>
              <span class="check"><i class="fal fa-check"></i></span>
            </a>
          </li>
        </base-dropdown>
      </div>
      <div class="notifications">
        <button>
          <img
            src="../../../../../public/img/kw-notifications.svg"
            alt="icon"
          />
        </button>
      </div>
      <div class="user">
        <base-dropdown
          title-classes="btn btn-sm btn-neutral"
          menu-on-right
          id="user-switcher"
          :has-toggle="false"
          menuClasses="user-switcher"
        >
          <template slot="title">
            <div class="user-toggle">
              <div class="image">
                <img
                  v-if="me?.profile_image"
                  :src="me?.profile_image"
                  alt="profile-picture"
                />
                <span>
                  {{ `${me?.firstname.charAt(0)}${me?.lastname.charAt(0)}` }}
                </span>
              </div>
              <ul>
                <li>{{ `${me?.firstname + " " + me?.lastname}` }}</li>
                <li>Administrateur</li>
              </ul>
              <i class="fal fa-chevron-down"></i>
            </div>
          </template>
          <!-- <li>
            <a href="#" class="settings">
              <img src="../../../../../public/img/kw-settings.svg" alt="icon" />
              <span>Paramètres</span>
            </a>
          </li> -->
          <li>
            <router-link
              :to="{ name: 'View Organization' }"
              class="settings"
              v-if="
                $currentUserCan($permissions.PERM_VIEW_OWN_ORGANIZATIONS) &&
                !!me.organization
              "
            >
              <i class="fas fa-building text-black-50"></i>
              <span> {{ $t("COMMON.MY_ORGANIZATION") }} </span>
            </router-link>
          </li>
          <li>
            <button class="logout" @click="logout">
              <img src="../../../../../public/img/kw-logout.svg" alt="icon" />
              <span>{{ $t("COMMON.LOGOUT") }}</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </Header>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
export default {
  components: {},

  mixins: [],

  data() {
    return {
      langs: [
        {
          id: 1,
          text: "Français",
          slug: "fr",
        },
        {
          id: 2,
          text: "Anglais",
          slug: "en",
        },
      ],
    };
  },

  methods: {
    selectLang(lang) {
      loadLanguageAsync(lang);
    },

    menuHoverObserver() {
      const firstMenuLinks = document.querySelectorAll(
        ".elite-content_sidebar_level1_menu_wrapper > ul > li > a"
      );
      for (const menuLink of firstMenuLinks) {
        menuLink.removeEventListener("click", this.menuLinkClickHandler);
      }
      for (const menuLink of firstMenuLinks) {
        menuLink.addEventListener("click", this.menuLinkClickHandler);
      }
    },

    logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {
    me: {
      handler: debounce(function (val) {
        this.menuHoverObserver();
      }),
      deep: true,
    },
  },
};
</script>
