export default {
  APP_NAME: "ELITE Manager",
  EMAIL: "Courriel",
  PASSWORD: "Mot de passe",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Mot de passe oublié ?",
  PAGE_NOT_FOUND: "La page demandée n'a pas été touvée",
  RETURN_HOME: "Retour a l'accueil",
  WELCOME: "Bienvenu",
  MY_PROFILE: "Mon profil",
  LOGOUT: "Déconnexion",
  DASHBOARD: "Tableau de bord",
  USERS: "Utilisateurs",
  ROLES: "Rôles",
  ORGANIZATIONS: "Campings",
  ORGANIZATION: "Camping",
  MY_ORGANIZATION: "Mon Camping",
  PRODUCTS: "Produits",
  PRODUCT: "Produit",
  ALL: "Tout",
  NAME: "Nom",
  SLUG: "Slug",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  CREATED_AT: "Crée le",
  SEARCH: "Recherche",
  PER_PAGE: "Par page",
  VIEW: "Voir",
  VIEW_DETAILS: "Voir détails",
  EDIT: "Modifier",
  EDIT_INFOS: "Modifier les données",
  DELETE: "Supprimer",
  DELETE_INFOS: "Supprimer les données",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  RETURN_TO_LIST: "Retour à la liste",
  SELECT_IMAGE: "Choisir une image",
  CHANGE_IMAGE: "Changer l'image",
  REMOVE_IMAGE: "Supprimer l'image",
  IMAGE: "Image",
  ROLE: "Rôle",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  RESELLERS: "Revendeurs",
  RESELLER: "Revendeur",
  LOCALE: "Langue",
  LOCALE_FR: "Francais",
  LOCALE_EN: "Anglais",
  GLOBAL: "Global",
  NONE: "Aucun",
  UPDATED_AT: "Mis à jour le",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "Toutes les campings",
  ALL_RESELLERS: "Tous les revendeurs",
  ALL_ROLES: "Tous les rôles",
  ALL_USERS: "Tous les utilisateurs",
  ALL_PRODUCTS: "Tous les produits",
  EXCERPT: "Description",
  OWNER: "Propriétaire",
  USER: "Utilisateur",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  PICTURE: "Photo",
  GALLERY: "Galerie",
  TAXES: "Taxes",
  PRICE: "Prix",
  RATE: "Taux (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Taxes par défaut",
  INVOICES: "Factures",
  INVOICE: "Facture",
  RECIPIENT: "Destinataire",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Régions",
  STATE: "Région",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Statut",
  ALL_STATUSES: "Tous les statuts",
  AMOUNT: "Montant",
  PAIED: "Payé",
  TOTAL: "Total",
  TOTAL_PAIED: "Total payé",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifier par",
  DETAILS: "Détails",
  PAYMENTS: "Paiements",
  UNIT_PRICE: "Prix unitaire",
  SUBTOTAL: "Sous-total",
  TOTAL_TAXES: "Total taxes",
  QUANTITY: "Quantité",
  DISCOUNT: "Réduction",
  REMAINING_PAYMENT_AMOUNT: "Balance",
  DISCOUNT_PERCENT: "Réduction (%)",
  CLOSE: "Fermer",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  LOGS: "Journaux",
  CAUSER: "Auteur",
  DATE: "Date",
  GO_BACK: "Retour",
  PACKAGE: "Forfait",
  PACKAGES: "Forfaits",
  ALL_PACKAGES: "Tous les forfaits",
  SUBSCRIPTION: "Inscription",
  SUBSCRIPTIONS: "Inscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Pourcentage",
  TAX_TYPE_AMOUNT: "Montant",
  PAYMENT: "Paiement",
  ALL_INVOICES: "Toutes les factures",
  DISCOUNTS: "Réductions",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandonner les modifications et quitter ?",
  COMPANY_NAME: "Nom de l'entreprise",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Particulier",
  BILLING_ENTITY_TYPE_COMPANY: "Entreprise",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Fournisseur",
  SCHOOL: "Ecole",
  TITLE: "Titre",
  TITLES: "Titres",
  ALL_TITLES: "Tous les titres",
  TITLE_MR: "Monsieur",
  TITLE_MDM: "Madame",
  TAG: "Etiquette",
  TAGS: "Etiquettes",
  ADD_TAG: "Ajouter une étiquette",
  BIRTHDAY: "Date d'anniverssaire",
  SUPPLIERS: "Fournisseurs",
  FISCAL_NUMBER: "Numéro fiscale",
  UPDATE: "Modifier",
  UPDATED: "Modifié",
  COLOR: "Couleur",
  LINK_CONTACT: "Lier le contact",
  CONTINUE: "Continuer",
  ADD_CONTACT: "Ajouter un contact",
  SOURCE: "Source",
  LOCATIONS: "Emplacements",
  LOCATION: "Emplacement",
  ALL_LOCATIONS: "Tous les emplacements",
  FILES: "Fichiers",
  FILE: "Fichier",
  ALL_FILES: "Tous les fichiers",
  FOLDERS: "Dossiers",
  FOLDER: "Dossier",
  ALL_FOLDERS: "Tous les dossiers",
  FILES_EXPLORER: "Explorateur",
  WAREHOUSE: "Entrepôt",
  WAREHOUSES: "Entrepôts",
  PURCHASES_ORDER: "Commande achat",
  PURCHASES_ORDERS: "Commandes achat",
  ISSUER: "Emetteur",
  PURCHASES_INVOICE: "Facture achat",
  PURCHASES_INVOICES: "Factures achat",
  PURCHASES_DELIVERY: "Livraison achat",
  PURCHASES_DELIVERIES: "Livraisons achat",
  PURCHASES_PAYMENT: "Paiement achat",
  PURCHASES_PAYMENTS: "Paiements achat",
  SALES_ORDER: "Commande vente",
  SALES_ORDERS: "Commandes vente",
  SALES_INVOICE: "Facture vente",
  SALES_INVOICES: "Factures vente",
  SALES_DELIVERY: "Livraison vente",
  SALES_DELIVERIES: "Livraisons vente",
  SALES_PAYMENT: "Paiement vente",
  SALES_PAYMENTS: "Paiements vente",
  BILLING_INFORMATIONS: "Information de facturation",
  BILLING_ADDRESS: "Adresse de facturation",
  RESELLER_PRODUCTS: "Produits revendeur",
  RESELLER_SERVICES: "Services revendeur",
  RESELLER_INVOICE: "Facture revendeur",
  RESELLER_INVOICES: "Factures revendeur",
  RESELLER_PAYMENTS: "Paiements revendeur",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Produit revendeur",
  RESELLER_SERVICE: "Service revendeur",
  APP: "Applications",
  DELIVERY_ADDRESS: "Adresse de livraison",
  OTHER_PHONES: "Autres téléphones",
  EXTENSION: "Extension",
  ALL_SUPPLIERS: "Tous les fournisseurs",
  PHONE_TYPE_MOBILE: "Mobile",
  PHONE_TYPE_HOME: "Domicile",
  PHONE_TYPE_OFFICE: "Bureau",
  PHONE_TYPE_BUSINESS: "Professionel",
  PHONE_TYPE_FAX: "Fax",
  PHONE_TYPE_PAGER: "Bipeur",
  CONFIRMATION: "Confirmation",
  PHONE_TYPE_OTHER: "Autre",
  SELLING_PRICE: "Prix de vente",
  BUYING_PRICE: "Prix d'achat",
  DESTINATION_WAREHOUSE: "Entrepot de destination",
  SOURCE_WAREHOUSE: "Entrepot source",
  STOCK_MOVEMENT: "Mouvement de stock",
  STOCK_MOVEMENTS: "Mouvements de stock",
  EXPECTED_QUANTITY: "Quantité attendue",
  DELIVERED_QUANTITY: "Quantité livrée",
  SAVE: "Enregistrer",
  SUCCESS_VERIFICATION:
    "Félicitations ! Vous avez réussi à vérifier votre code de sécurité.",
  ERROR_VERIFICATION: "Le code de vérification est erroné",
  VERIFICATION_CODE: "Code de vérification",
  DELIVERY_INFORMATIONS: "Informations livraison",
  RESEND_CODE: "Renvoyez le code",
  ENABLE_2FA:
    "Souhaitez-vous activer la vérification en deux étapes pour sécuriser davantage votre compte ?",
  DISABLE_2FA:
    "Souhaitez-vous désactiver la vérification en deux étapes et revenir à la connexion sans 2FA ?",
  IMPORTS: "Imports",
  IMPORT: "Import",
  MY_LOCATIONS: "Mes emplacements",
  NEXT_STEP: "Étape suivante",
  PREV_STEP: "Étape précédente",
  CANCEL: "Annuler",
  DOWNLOAD_CSV_FILE: "Télécharger le fichier csv",
  RETURN_TO_LOGIN: "Retour à la connexion",
  RESET_PASSWORD_SENT:
    "Un e-mail contenant un lien de réinitialisation du mot de passe a été envoyé.",
  RESET_PASSWORD_USER_NOT_FOUND:
    "Nous ne trouvons pas d'utilisateur avec cette adresse e-mail.",
  SEND_PASSWORD_RESET_LINK:
    "Envoyer le lien de réinitialisation du mot de passe",
  RESET_PASSWORD: "Réinitialiser le mot de passe",
  PASSWORD_UPDATED: "Mot de passe mis a jour",
  TOKEN_ERROR:
    "Token de changement de mot de passe invalide merci de reessayer",
  EMAIL_ERROR: "Email invalide merci de reessayer",
  PROPERTIES: "Propriétés",
  OWNERS: "Propriétaires",
  TAXABLE: "Taxable",
  ENABLE: "Activé",
  ACTIVE: "Actif",
  BATCH: "Lot",
  BATCHES: "Lots",
  DISABLE: "Désactivé",
  START_TYPING_CITY_REGION_COUNTRY:
    "Commencez par saisir la ville, la région ou le pays pour completer l'adresse",
  AUCTION: "Encan",
  AUCTIONS: "Encans",
  EXPORT: "Exporter",
  LONGITUDE: "Longitude",
  LATITUDE: "Latitude",
  MANAGER: "Gestionnaire",
  MANAGERS: "Gestionnaires",
  START_AT: "Date début",
  END_AT: "Date fin",
  PAYMENT_SOURCE: "Source de paiement",
  PAYMENT_SOURCE_MANUAL: "Manuel",
  PAYMENT_SOURCE_PAYPAL: "Paypal",
  PAYMENT_SOURCE_STRIPE: "Stripe",
  PAYMENT_SOURCE_CASH: "Espèces",
  LOADING: "Chargement",
  MRC_FEES: "Frais MRC",
  MINIMUM_AMOUNT: "Montant minimum",
  MAXIMUM_AMOUNT: "Montant maximum",
  ADD_ITEM: "Ajouter",
  UPDATE_ITEM: "Modifier",
  TO_THE_ATTENTION_OF: "A l'attention de",
  FIRSTNAME_LASTNAME: "Nom et Prénoms",
  DELETE_ITEM: "Supprimer",
  MME: "Mme",
  MR: "Mr",
  OTHER: "Autre",
  QUEBEC: "Québec",
  NOTIFICATIONS: "Notifications",
  BID_AMOUNT: "Montant du bond",
  BID_STEPS: "Bonds de mises",
  ITEM_ADDED: "Ligne ajoutée",
  ITEM_EDITED: "Ligne modifiée",
  ITEM_DELETED: "Ligne supprimée",
  PROPERTY_TRANSACTIONS: "Transaction propriétés",
  BIDS: "Mises",
  SPOTS: "Emplacements",
  CATEGORY: "Catégorie",
  PARENT_CATEGORY: "Catégorie parent",
  COMMENT: "Commentaire",
  CONFIGURATION: "Configuration",
  SPOT_CATEGORIES: "Catégories d'emplacement",
  EQUIPMENT_CATEGORIES: "Catégories d'équipement",
  SPOT_TYPES: "Types d'emplacement",
  EQUIPMENTS: "Équipements",
  SELECT_ALL: "Tout sélectionner",
  EXPORT_LIST: "Exporter la liste",
  GENERAL_INFORMATIONS: "Informations générales",
  USER_DETAILS: "Détails de l'utilisateur",
  LOCATION_DETAILS: "Détails de l'emplacement",
  ORGANIZATION_DETAILS: "Détails du camping",
  INVOICE_DETAILS: "Détails de la facture",
  PACKAGE_DETAILS: "Détails du forfait",
  PAYMENT_DETAILS: "Détails du paiement",
  SUBSCRIPTION_DETAILS: "Détails de l'inscription",
  PRODUCT_DETAILS: "Détails du produit",
  ROLE_DETAILS: "Détails du rôle",
  IMPORT_DETAILS: "Détails de l'import",
  ATTRIBUTION: "Attribution",
  USER_INFORMATION: "Informations sur l’utilisateur",
  RESELLER_INFORMATION: "Informations sur le revendeur",
  ORGANIZATION_INFORMATION: "Informations sur le camping",
  LOCATION_INFORMATION: "Informations sur l’emplacement",
  ROLE_INFORMATION: "Informations sur le rôle",
  EXPAND_MODAL: "Agrandir la fenêtre",
  REDUCE_MODAL: "Reduire la fenêtre",
  SUBSCRIPTIONS_DETAILS: "Informations sur l'inscription",
  USERS_DETAILS: "Informations de l'utilisateur",
  BOOKINGS: "Réservations",
  APPLY: "Appliquer",
  FILTERS: "Filtres",
  RESET_FILTERS: "Réinitialiser",
  UNIT_FEET: "pieds",
  UNIT_SQUARE_FOOT: "pied carré",
  END: "Terminer",
  NO_SEARCH_RESULT: "Aucun résultat trouvé",
  ONGOING_LOADING: "Chargement en cours",
  OPTIONS: "Configurations",
  SHOPPING_CART: "Panier",
  RESERVATION_ON_CLICK: "Réservez un emplacement en quelques clics",
  MORE_INFOS: "Demande d’info",
  RESERVATION: "Réserver",
  PREVIOUS: "Précédent",
  REDUCE: "Réduire",
  SHOW_SPOTS: "Voir emplacements",
  COPIED: "Copié!!",
};
