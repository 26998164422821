export const PHONE_TYPE_MOBILE = "MOBILE";
export const PHONE_TYPE_HOME = "HOME";
export const PHONE_TYPE_OFFICE = "OFFICE";
export const PHONE_TYPE_BUSINESS = "BUSINESS";
export const PHONE_TYPE_FAX = "FAX";
export const PHONE_TYPE_PAGER = "PAGER";
export const PHONE_TYPE_OTHER = "OTHER";

export const phoneTypesOptions = {
  [PHONE_TYPE_MOBILE]: "MOBILE",
  [PHONE_TYPE_HOME]: "HOME",
  [PHONE_TYPE_OFFICE]: "OFFICE",
  [PHONE_TYPE_BUSINESS]: "BUSINESS",
  [PHONE_TYPE_FAX]: "FAX",
  [PHONE_TYPE_PAGER]: "PAGER",
  [PHONE_TYPE_OTHER]: "OTHER",
};

export const SPOT_STATUS_AVAILABLE = "AVAILABLE";
export const SPOT_STATUS_NOT_AVAILABLE = "NOT_AVAILABLE";

export const spotStatusOptions = {
  [SPOT_STATUS_AVAILABLE]: "AVAILABLE",
  [SPOT_STATUS_NOT_AVAILABLE]: "NOT_AVAILABLE",
};

export const SPOT_CONDITION_CLEAN = 'CLEAN';
export const SPOT_CONDITION_DIRTY = 'DIRTY';

export const spotConditionOptions = {
  [SPOT_CONDITION_CLEAN]: "CLEAN",
  [SPOT_CONDITION_DIRTY]: "DIRTY",
};

export const FORM_TYPE_DEFAULT = 'DEFAULT';
export const FORM_TYPE_LAND = 'LAND';
export const FORM_TYPE_PROPERTY = 'PROPERTY';

export const FORM_TYPES = [
  FORM_TYPE_DEFAULT,
  FORM_TYPE_LAND,
  FORM_TYPE_PROPERTY,
];

export const TENT = 'TENT';
export const TENT_TRAILER = 'TENT_TRAILER';
export const TRAILER = 'TRAILER';
export const VAN = 'VAN';
export const MOTORHOME = 'MOTORHOME';

export const ALLOWED_CUSTOMER_EQUIPMENT = [TENT, TENT_TRAILER, TRAILER, VAN, MOTORHOME];

export const QUERY_ACTIONS_VIEW = "view";
export const QUERY_ACTIONS_EDIT = "edit";
export const QUERY_ACTIONS_ADD = "add";
