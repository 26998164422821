export default {
  SPOT_TYPE_LIST: "Liste des types d'emplacement",
  ADD_SPOT_TYPE: "Ajouter un type d'emplacement",
  SPOT_TYPE_ADDED: "Type d'emplacement ajouté",
  EDIT_SPOT_TYPE: "Modifier le type d'emplacement",
  SPOT_TYPE_UPDATED: "Type d'emplacement mis à jour",
  DELETE_THIS_SPOT_TYPE: "Voulez-vous supprimer le type d'emplacement sélectionné ?",
  DELETE_THIS_SPOT_TYPE_SUCCESS: "La suppression du type d'emplacement a été effectuée avec succès",
  SPOT_TYPE_DELETED: "Type d'emplacement supprimé",

  SPOT_TYPE_NAME: "Nom du type d'emplacement",
  SERVICES: "Services",
  ELECTRICITY: "Électricité",
  OTHER_EQUIPMENT: "Autres Équipements",
  VIEW_SPOT_TYPE: "Type d'emplacement",
  DETAILS_SPOT_TYPE: "Détails du type d'emplacement",

  WITH_SERVICE: "Avec Services",
  WITHOUT_SERVICE: "Sans Services",

  "20_AMPS": "20 Amp Électricité",
  "30_AMPS": "30 Amp Électricité",
  "50_AMPS": "50 Amp Électricité",

  SAME_SPOT_TYPES: 'Un ou plusieurs types d\'emplacements ont déjà été enregistrés avec le nom {name}. S\'agit-il du même type d\'emplacement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA: "Vous n’avez ajouté aucun type d'emplacement pour le moment, cliquer sur le bouton pour ajouter.",
  SPOT_TYPE_INFORMATION: "Informations sur le type d’emplacement",
};
