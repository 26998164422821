import permissions from "../constants/permissions";
import SpotModuleLayout from "@/views/Pages/SpotModule/SpotModuleLayout.vue";
import SpotModuleMenu from "@/views/Pages/SpotModule/SpotModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListSpotPage = () =>
  import("@/views/Pages/SpotModule/SpotManagement/ListSpotPage.vue");

const SpotPage = () => import("@/views/Pages/SpotModule/SpotPage.vue");

let spotManagementPages = {
  path: "spots",
  components: {
    default: SpotModuleLayout,
    SidebarSecondLevelMenu: SpotModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Spots",
      components: { default: ListSpotPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SPOTS,
        hasLevel2: true,
      },
    },
  ],
};

const spotModuleRoutes = [
  {
    path: "",
    components: {
      default: SpotModuleLayout,
      SidebarSecondLevelMenu: SpotModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Spot Page",
        components: { default: SpotPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  spotManagementPages,
];

export default spotModuleRoutes;
