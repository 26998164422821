export default {
  EQUIPMENT_LIST: "Liste des équipements",
  ADD_EQUIPMENT: "Ajouter un équipement",
  EQUIPMENT_ADDED: "Équipement ajouté",
  EDIT_EQUIPMENT: "Modifier l'équipement",
  EQUIPMENT_UPDATED: "Équipement mis à jour",
  DELETE_THIS_EQUIPMENT: "Voulez-vous supprimer l’équipement sélectionné ?",
  DELETE_THIS_EQUIPMENT_SUCCESS: "La suppression de l’équipement a été effectuée avec succès",
  EQUIPMENT_DELETED: "Équipement supprimé",
  EQUIPMENT_NAME: "Nom de l'équipement",
  EQUIPMENT_CATEGORY_NAME: "Catégorie d'équipement",
  SAME_EQUIPMENTS: 'Un ou plusieurs équipements existent déja avec le nom {name}. S\'agit-il du même équipement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  VIEW_EQUIPMENT: "Équipement",
  DETAILS_VIEW_EQUIPMENT: "Détail de l'équipement",
  NO_DATA: "Vous n’avez ajouté aucun équipement pour le moment, cliquer sur le bouton pour ajouter.",
  EQUIPMENTS: "Équipements",
  EQUIPMENT_INFORMATIONS: "Informations sur l’équipement"
};
