export default {
  RESELLERS_LIST: "Liste des revendeurs",
  ADD_RESELLER: "Ajouter un revendeur",
  RESELLER_ADDED: "Revendeur ajouté",
  EDIT_RESELLER: "Modifier un revendeur",
  RESELLER_UPDATED: "Revendeur mis à jour",
  DELETE_THIS_RESELLER: "Supprimer ce revendeur ?",
  RESELLER_DELETED: "Revendeur supprimé",
  CONFIG_MANAGER: "Configuration du manager",
  CONFIG_MANAGER_APP_LOGO: "Logo",
  CONFIG_MANAGER_APP_NAME: "Nom de l'application",
  CONFIG_MANAGER_URL_REGEX: "Regex de l'url",
  VIEW_RESELLER: "Revendeur",
  NO_DATA: "Vous n'avez ajouté aucun revendeur pour le moment, cliquer sur le bouton pour ajouter.",
};
