<template>
  <div>
    <router-view v-if="appConfig"></router-view>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        return [
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [{ rel: "icon", type: "image/png", href: "/favicon.ico" }],
      script: (function () {})(),
    };
  },

  computed: {
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },

  data() {
    return {};
  },

  async mounted() {
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      },
    });

    await this.$store.dispatch("profile/me");
  },

  watch: {
    appConfig: {
      handler: function (val) {
        if (val) {
          swal.close();
        }
      },
      deep: true,
    },
  },
};
</script>
