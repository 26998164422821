import service from "@/store/services/permissions-service";
import permissionsList from "@/constants/permissions";

export const state = {
  list: {},
  permission: {},
  meta: {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list.filter((perm) => {
      return ![
        permissionsList.PERM_VIEW_APP_SALES,
        permissionsList.PERM_VIEW_APP_PURCHASES,
        permissionsList.PERM_VIEW_APP_INVENTORY,
        permissionsList.PERM_VIEW_APP_FILE_EXPLORER,
        permissionsList.PERM_VIEW_APP_AUCTION,
      ].includes(perm.name);
    });;
  },
  SET_RESOURCE: (state, permission) => {
    state.permission = permission;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((permission) => {
      commit("SET_RESOURCE", permission);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  permission: (state) => state.permission,
  dropdown: (state) => {
    return state.list.map((permission) => ({
      id: permission.id,
      name: permission.name,
    }));
  },
};

const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default permissions;
