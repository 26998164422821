import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import VueI18n from "vue-i18n";

//plugins
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import VueMoment from "vue-moment";
import { i18n } from "./plugins/i18n";

import "./assets/css/style.css";

// router&store setup
import router from "./router";
import store from "./store";
import auth from "./plugins/auth";
import formatCurrency from "./plugins/formatCurrency";
import portalUrl from "./plugins/portalUrl";
import objectRoute from "./plugins/objectRoute";
import objectDenomination from "./plugins/objectDenomination";
import flatPickrLocale from "./plugins/flatPickrLocale";
import fillUserOrganizationData from "./plugins/fillUserOrganizationData";
import fillUserResellerData from "./plugins/fillUserResellerData";
import showRequestError from "./plugins/showRequestError";
import formatDate from "./plugins/formatDate";
import numberFormat from "./plugins/numberFormat";
import convertDateToUtc from "./plugins/convertDateToUtc";
import VueScrollTo from "vue-scrollto";
import VueClipboard from "vue-clipboard2";

const moment = require("moment");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(auth);
Vue.use(VueMeta, { keyName: "head" });
Vue.use(VueI18n);
Vue.use(formatCurrency);
Vue.use(portalUrl);
Vue.use(objectRoute);
Vue.use(objectDenomination);
Vue.use(flatPickrLocale);
Vue.use(fillUserOrganizationData);
Vue.use(fillUserResellerData);
Vue.use(showRequestError);
Vue.use(formatDate);
Vue.use(numberFormat);
Vue.use(convertDateToUtc);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueClipboard);

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   render: h => h(App),
//   router
// });
/* eslint-disable no-new */

const app = new Vue({
  i18n: i18n,
  router: router,
  store: store,
  el: "#app",
  render: (h) => h(App),
});

store.$app = app;
