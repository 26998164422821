<template>
  <div class="kampwise-content_body_inner_container">
    <!--<div class="configuration-wrapper">
       <h1>{{ $t("APPS.CONFIGURATION_MODULE") }}</h1>
      <div class="kw-breadcrumb">
        <ul>
          <li>
            <img src="../../../../public/img/kw-home.svg" alt="icon" />
            <span>{{ $t("APPS.CONFIGURATION_MODULE") }}</span>
          </li>
          <li v-if="$route.name === 'List SpotCategories'">
            {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
          </li>
          <li v-if="$route.name === 'List SpotTypes'">
            {{ $t("CONFIGURATION.SPOT_TYPES") }}
          </li>
          <li v-if="$route.name === 'List Equipments'">
            {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
          </li>
          <li v-if="$route.name === 'List EquipmentCategories'">
            {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
          </li>
        </ul>
      </div>
       <ul class="sub-menu-items">
        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_CATEGORIES)"
        >
          <router-link
            :to="{ name: 'List SpotCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_TYPES)">
          <router-link
            :to="{ name: 'List SpotTypes' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_TYPES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES)
          "
        >
          <router-link
            :to="{ name: 'List EquipmentCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)">
          <router-link
            :to="{ name: 'List Equipments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul> 
    </div>-->
    <router-view></router-view>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {
      currentRoute: "",
    };
  },

  methods: {},

  async mounted() {},

  computed: {
    currentRouteName() {
      return this.$route;
    },
  },

  watch: {},
};
</script>
