import permissions from "../constants/permissions";
import DefaultModuleLayout from "@/views/Pages/DefaultModule/DefaultModuleLayout.vue";

// import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

// import pages
const ViewLogPage = () =>
  import("@/views/Pages/DefaultModule/LogManagement/ViewLogPage.vue");
const ViewProfilePage = () =>
  import("@/views/Pages/DefaultModule/UserProfile/ViewProfilePage.vue");
const EditProfilePage = () =>
  import("@/views/Pages/DefaultModule/UserProfile/EditProfilePage.vue");
const ListNotificationPage = () =>
  import(
    "@/views/Pages/DefaultModule/NotificationManagement/ListNotificationPage.vue"
  );
const ViewNotificationPage = () =>
  import(
    "@/views/Pages/DefaultModule/NotificationManagement/ViewNotificationPage.vue"
  );
const ViewOrganizationPage = () =>
  import(
    "@/views/Pages/DefaultModule/OrganizationManagement/ViewOrganizationPage.vue"
  );
const EditOrganizationPage = () =>
  import(
    "@/views/Pages/DefaultModule/OrganizationManagement/EditOrganizationPage.vue"
  );

let logManagementPages = {
  path: "logs",
  component: DefaultModuleLayout,
  children: [
    {
      path: "view/:id",
      name: "View Log",
      components: { default: ViewLogPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_LOGS,
      },
    },
  ],
};

let notificationManagementPages = {
  path: "notifications",
  component: DefaultModuleLayout,
  children: [
    {
      path: "",
      name: "List Notifications",
      components: { default: ListNotificationPage },
      meta: { middleware: authenticated },
    },
    {
      path: "view/:id",
      name: "View Notification",
      components: { default: ViewNotificationPage },
      meta: { middleware: authenticated },
    },
  ],
};

let userProfilePages = {
  path: "me",
  component: DefaultModuleLayout,
  children: [
    {
      path: "",
      name: "View Profile",
      components: { default: ViewProfilePage },
      meta: { middleware: authenticated },
    },
    {
      path: "change",
      name: "Edit Profile",
      components: { default: EditProfilePage },
      meta: { middleware: authenticated },
    },
  ],
};

let OrganizationManagementPages = {
  path: "organization",
  component: DefaultModuleLayout,
  children: [
    {
      path: "",
      name: "View Organization",
      components: { default: ViewOrganizationPage },
      meta: {
        middleware: authenticated,
        permission: permissions.PERM_VIEW_OWN_ORGANIZATIONS,
      },
    },
    {
      path: "change",
      name: "Edit Organization",
      components: { default: EditOrganizationPage },
      meta: {
        middleware: authenticated,
        permission: permissions.PERM_EDIT_OWN_ORGANIZATIONS,
      },
    },
  ],
};

const defaultModuleRoutes = [
  userProfilePages,
  logManagementPages,
  notificationManagementPages,
  OrganizationManagementPages,
];

export default defaultModuleRoutes;
